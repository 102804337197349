/* eslint-disable */
import emailjs from 'emailjs-com'
import { useState, useEffect, React } from 'react'
import ReactTooltip from "react-tooltip";
import * as txt from "../data/toolTip.js";
import ReCAPTCHA from 'react-google-recaptcha';
import StatusAlert, { StatusAlertService } from 'react-status-alert'
import 'react-status-alert/dist/status-alert.css'

const initialState = {
  name: '',
  email: '',
  message: '',
}

let recaptchaInstance;

export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState)

  // Handle form changes
  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  //  Capture form submit and trigger the recaptcha
  const handleSubmit = (e) => {
    e.preventDefault()
    recaptchaInstance.execute();
  }

  //  Capture recaptcha response and package the request parameters
  const prepareDataAndSendEmail = (captchaValue) => {
    const mailParams = {
      'name': name,
      'email': email,
      'message': message,
      'g-recaptcha-response': captchaValue,
    }
    sendEmail(mailParams)
  }

  //  Send mail usin the mailjs library
  const sendEmail = (e) => {
    //  console.log('Sending Mail', e)
    emailjs
      .send(
        'service_hlid8jo', 'contact_us_form', e, 'user_pIBKo1b5aFtaKuE6x2cfk'
      )
      .then(
        (result) => {
          StatusAlertService.showSuccess('Your email has been sent. We will contact you shortly');
          //  console.log('Result', result.text)
          clearState()
        },
        (error) => {
          StatusAlertService.showError('Sorry your email could not be sent. Please try again later');
          //  console.log('Error', error.text)
        }
      )
  }

  //  Hover over implementation
  useEffect(() => {
        ReactTooltip.rebuild();
    }); 

  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-12'>
            <div className='row'>
              <div className='section-title'>
                <h2>Get In Touch</h2>
                <p>
                 Please complete the following details before proceeding. The information provided will be kept confidential by Gadfly and not be disclosed to third parties.
                </p>
              </div>
              <form name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Name'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Message'
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'>
                  <button type='submit' className='btn btn-custom btn-lg'>
                  Send Message
                </button>
                </div>
                  <div>
                    <ReCAPTCHA
                      ref={e => recaptchaInstance = e}
                      sitekey="6LcsYbsbAAAAALyn4QTkWeOc1Ff0bgH4hKxTEJCS"
                      size="invisible"
                      onChange={prepareDataAndSendEmail}
                    />
                 </div>
                </form>
            </div>
          </div>
        </div>
      </div>
      <StatusAlert/>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; Copyright Gadfly Limited. All rights reserved.
          </p>
        </div>
      </div>
      <ReactTooltip id="cookieTip" place="right" effect="float">
        <div className="adp-tooltip--title">
          <h3>Cookie policy</h3>
        </div>
        <div className="adp-tooltip--title">
          <h6>Overview</h6>
        </div>
        <div className="adp-tooltip--para">
        <p>Cookie policy stuff.</p>
        </div>
      </ReactTooltip>
      <ReactTooltip id="privacyTip" place="left" effect="float">
        <div className="adp-tooltip--title">
          <h3><b>Privacy policy</b></h3>
        </div>
        <div className="adp-tooltip--title">
          <h6>Overview</h6>
        </div>
        <div className="adp-tooltip--para">
          <p>Privacy policy stuff</p>
        </div>
      </ReactTooltip>
      <ReactTooltip id="bobTip" place="left" effect="float">
        <div className="adp-tooltip--title">
          <h3>{txt.bobName}</h3>
        </div>
        <div className="adp-tooltip--title">
          <h6>{txt.bobTitle}</h6>
        </div>
        <div className="adp-tooltip--para">
          <p>{txt.bobText1}</p>
        </div>
        <div className="adp-tooltip--para">
          <p>{txt.bobText2}</p>
        </div>
      </ReactTooltip>
      <ReactTooltip id="chrisTip" place="left" effect="float">
        <div className="adp-tooltip--title">
          <h3>{txt.chrisName}</h3>
        </div>
        <div className="adp-tooltip--title">
          <h6>{txt.chrisTitle}</h6>
        </div>
        <div className="adp-tooltip--title">
          <h6>{txt.chrisText1}</h6>
        </div>
        <div className="adp-tooltip--para">
          <p>{txt.chrisText2}</p>
        </div>
        <div className="adp-tooltip--para">
          <p>{txt.chrisText3}</p>
        </div>
        <div className="adp-tooltip--para">
          <p>{txt.chrisText4}</p>
        </div>
        <div className="adp-tooltip--para">
          <p>{txt.chrisText5}</p>
        </div>
      </ReactTooltip>
      <ReactTooltip id="lambTip" place="left" effect="float">
        <div className="adp-tooltip--title">
          <h3>{txt.lambName}</h3>
        </div>
        <div className="adp-tooltip--title">
          <h6>{txt.lambTitle}</h6>
        </div>
        <div className="adp-tooltip--para">
          <p>{txt.lambText1}</p>
        </div>
        <div className="adp-tooltip--para">
          <p>{txt.lambText2}</p>
        </div>
        <div className="adp-tooltip--para">
          <p>{txt.lambText3}</p>
        </div>
        <div className="adp-tooltip--para">
          <p>{txt.lambText4}</p>
        </div>
      </ReactTooltip>
   </div>
  )
}
