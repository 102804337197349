export const Services = (props) => {
  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Capability</h2>
          <p>{props.data ? props.data.paragraph1 : 'loading...'}</p>
          <br></br>
          <p>{props.data ? props.data.paragraph2 : 'loading...'}</p>
          <br></br>
          <p>{props.data ? props.data.paragraph3 : 'loading...'}</p>
          <br></br>
          <p>{props.data ? props.data.paragraph4 : 'loading...'}</p>
        </div>
      </div>
    </div>
  )
}
