const lambName = "Lambros Zigouras";
const lambTitle = "Chief Operations Officer";
const lambText1 = "Lambros has almost 30 years experience and extensive exposure across numerous industries (service and manufacturing) using leading edge methodologies and technologies in the development and installation of innovative strategic, process, engineering and technical solutions.";
const lambText2 = "He has successfully delivered significant operational capacity and financial performance improvements to business operations within strict budget timelines and constraints. ";
const lambText3 = "He has a deep understanding of modelling techniques, derived from his long exposure and management of complex IT platform integration projects.";
const lambText4 = "His academic qualifications include a Bachelor of Engineering (Industrial & Computing) with honours.";
const chrisName = "Chris Brock";
const chrisTitle = "Managing Director";
const chrisText1 = "Founding member of Gadfly in 1999.";
const chrisText2 = "Over 30 years of Management Consulting experience, including 20 in an Executive role.";
const chrisText3 = "Led major turnarounds in multiple, asset-intensive and service industry sectors on every non-polar continent.";
const chrisText4 = "Deep expertise in business & capital projects strategy, strategic asset management, risk management & operational performance improvement implementation.";
const chrisText5 = "A graduate of ‘Greats’ and former Exhibitioner of Trinity College, Oxford University.";
const bobName = "Bob Gregg";
const bobTitle = "Chief Executive Officer";
const bobText1 = "Bob has over 30 years experience in Engineering Design, Documentation and Contract Administration of Civil, Structural and Planning Projects, Project Management and Construction Management in a broad range of disciplines and market sectors. Sector experience includes mining and resources, infrastructure and urban development.";
const bobText2 = "His qualifications include a Bachelor Technology (Eng), Diploma of Civil Engineering, Diploma of Environmental Design, Fellow  - Australian Institute of Company Directors and a Registered Builder, Queensland (QBCC Lic.)";
export {
    lambName,
    lambTitle,
    lambText1,
    lambText2,
    lambText3,
    lambText4,
    chrisName,
    chrisTitle,
    chrisText1,
    chrisText2,
    chrisText3,
    chrisText4,
    chrisText5,
    bobName,
    bobTitle,
    bobText1,
    bobText2,
};
